import React, { useState } from "react"
import { navigate } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Admission() {
  const [state, setState] = React.useState({})
  // const [close, setClose] = useState(true)
  // function closeDropdown() {
  //   if (!close) setClose(true)
  //   else setClose(false)
  // }
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  const lessons = [
      {
      label: "Reformer Pilates",
      value: "reformer",
    },
     {
      label: "Kickboks",
      value: "kickboks",
    },
       {
      label: "Fonksiyonel Antrenman",
      value: "fonksiyonel",
    },
      {
      label: "Mat Pilates",
      value: "mat",
    },
    {
      label: "Grup Ders",
      value: "grup",
    },
      {
      label: "Diyetisyen",
      value: "diyetisyen",
    },
    {
      label: "Cadillac Pilates",
      value: "cadillac",
    },
  
    {
      label: "Hamile Pilates",
      value: "hamile",
    },
    {
      label: "İleri Yaş Pilates",
      value: "ileri_yas",
    },
    {
      label: "Çocuk Pilates",
      value: "cocuk",
    },
    {
      label: "Zumba",
      value: "zumba",
    },
    {
      label: "Boks",
      value: "boks",
    },
   
  ]

  return (
    <section
      className="section mb-4 admission-background"
      style={{
        background:
          "url(https://res.cloudinary.com/carrara-pilates/image/upload/c_scale,q_80,w_2070/v1585161146/gallery/gallery_carrara_46_qbvebr.jpg) center center",
        backgroundSize: "cover",
      }}
      id="admission"
    >
      <div className="bg-overlay" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-7 col-12">
            <div className="section-title">
              <h4 className="main-title text-white mb-4">Hemen Kaydol</h4>
              <p className="para-desc mb-0 text-light">
                Carrara Pilates ile sağlıklı bir başlangıç yapın. Ücretsiz
                deneme dersi almak için bize ulaşın.
              </p>
            </div>
            <div className="row" id="counter">
              <div className="col-md-3 col-6 mt-4 pt-2"></div>
              {/*end col*/}
              <div className="col-md-3 col-6 mt-4 pt-2"></div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end col*/}
          <div className="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <form
              netlify
              name="admission"
              method="post"
              action="/tesekkurler/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
              className="p-4 rounded bg-white"
            >
              <input type="hidden" name="form-name" value="admission" />
              <h4>Ücretsiz Deneme</h4>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group position-relative">
                    <label>
                      Adınız ve Soyadınız <span className="text-danger">*</span>
                    </label>
                    <i className="mdi mdi-account ml-3 icons" />
                    <input
                      type="text"
                      className="form-control pl-5"
                      placeholder=""
                      name="name"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group position-relative">
                    <label>
                      E-Posta Adresiniz<span className="text-danger">*</span>
                    </label>
                    <i className="mdi mdi-email ml-3 icons" />
                    <input
                      type="email"
                      className="form-control pl-5"
                      placeholder=""
                      name="email"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-12">
                  <div className="form-group position-relative">
                    <label>
                      Telefon <span className="text-danger">*</span>
                    </label>
                    <i className="mdi mdi-phone ml-3 icons" />
                    <input
                      type="phone"
                      id="phone"
                      name="phone"
                      className="form-control pl-5"
                      placeholder=""
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div class="form-group">
                    <label for="exampleFormControlSelect1">
                      İlgilendiğiniz Dersi Seçiniz
                    </label>
                    <select
                      class="form-control"
                      id="exampleFormControlSelect1"
                      onChange={handleChange}
                      onSelect={handleChange}
                      name="selectedLesson"
                      >
                      {lessons.map(data => (
                        <option
                          value={data.value}
                          id={data.value}
                          name="lesson"
                          type="select"
                        >
                          {data.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-12 mt-2 mb-0">
                  <button type="submit" className="btn btn-primary w-100">
                    Kaydol
                  </button>
                </div>
                {/*end col*/}
              </div>
            </form>
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
    </section>
  )
}
