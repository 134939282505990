import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Admission from "../components/admission"

export default ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      />
      <section className="mt-5">
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 text-center">
                  <div className="page-next-level">
                    <h1>{post.frontmatter.title}</h1>
                    <ul className="list-unstyled mt-4">
                      <li className="list-inline-item h6 user text-muted mr-2">
                        <i className="mdi mdi-account" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="mr-lg-3">
                <div className="blog position-relative overflow-hidden shadow rounded">
                  <div className="position-relative">
                    {post.frontmatter.BlogImage ? (
                      <img
                        loading='lazy'
                        className="img-fluid rounded-top"
                        alt={`Carrara Pilates ${post.frontmatter.description}`}
                        src={post.frontmatter.BlogImage}
                      />
                    ) : null}
                  </div>

                  <div className="content p-4">
                    <p className="text-muted mt-3">
                      <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    </p>
                    <div className="post-meta mt-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-4 pt-2">
              {post.frontmatter.SubPageImageOne ? (
                <img
                  loading='lazy'
                  className="img-fluid rounded"
                  alt={`Carrara Pilates ${post.frontmatter.description}`}
                  src={post.frontmatter.SubPageImageOne}
                />
              ) : null }
            </div>
            <div className="col-md-6 mt-4 pt-2">
            {post.frontmatter.SubPageImageTwo ? (
                <img
                  loading='lazy'
                  className="img-fluid rounded"
                  alt={`Carrara Pilates ${post.frontmatter.description}`}
                  src={post.frontmatter.SubPageImageTwo}
                />
              ) : null } 
            </div>
          </div>
          <div className="bg-light rounded p-4 mt-4 pt-2 mb-5">
            <p className="text-muted font-italic mb-0">
              {post.frontmatter.description}
            </p>
          </div>
        </div>
      </section>
      <Admission />
      <div className="post-meta mt-5"></div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        BlogImage 
        SubPageImageOne
        SubPageImageTwo
      }
      excerpt(pruneLength: 150)
    }
  }
`
